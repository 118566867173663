<template>
	<div class="orderList">
		<div class="search">
			<div class="search-l">
				<div class="l-item" style="width: 40%;">
					<div class="item-label">退库日期:</div>
					<div class="item-input" style="width: 80%;">
						<el-date-picker v-model="dateVal" type="datetimerange" range-separator="至"
							start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"
							:default-time="['00:00:00', '23:59:59']" style="width: 100%;">
						</el-date-picker>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">退库仓库:</div>
					<div class="item-input">
						<el-select v-model="searchForm.warehouse_id" placeholder="请选择">
							<el-option v-for="item in warehouseOptions" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">退回供应商:</div>
					<div class="item-input">
						<el-select v-model="searchForm.erp_supplier_id" filterable placeholder="请选择供应商"
							style="width: 100%;">
							<el-option v-for="item in supplierList" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">状态:</div>
					<div class="item-input">
						<el-select v-model="searchForm.status" placeholder="请选择">
							<el-option v-for="item in statusOptions" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">结清状态:</div>
					<div class="item-input">
						<el-select v-model="searchForm.settlemented_status" placeholder="请选择结清状态">
							<el-option v-for="item in settleOptions" :key="item.id" :label="item.name" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<el-button type="warning"
						@click.stop="loading = true, page = 1, searchForm.begin = dateVal[0], searchForm.end = dateVal[1], getList()">搜索</el-button>
					<el-button
						@click.stop="loading = true, page = 1, pageNum = 10, searchForm = {}, searchForm.begin = dateVal[0], searchForm.end = dateVal[1], getList()">重置</el-button>
				</div>
				<i></i>
				<i></i>
				<i></i>
				<i></i>
			</div>
		</div>
		<div class="table">
			<div class="table-operation">
				<el-button type="info" icon="el-icon-refresh-left" @click.stop="loading = true, getList()"></el-button>
			</div>
			<el-table :data="tableData" style="width: 100%" border :header-cell-style="{
				background: '#f5f7fa',
				fontWeight: 'bold',
				color: '#303133'
			}" v-loading="loading">
				<el-table-column type="expand">
					<template slot-scope="props">
						<el-table :data="props.row.order_goods" :header-cell-style="{
							background: '#f5f7fa',
							fontWeight: 'bold',
							color: '#303133'
						}">
							<el-table-column prop="goods_name" label="商品名称" align="center"></el-table-column>
							<el-table-column label="商品主图" align="center">
								<template slot-scope="scope">
									<el-image style="width: 50px; height: 50px" :src="scope.row.goods_thumb"
										:preview-src-list="[scope.row.goods_pic]">
									</el-image>
								</template>
							</el-table-column>
							<el-table-column prop="goods_intro" label="商品卖点" align="center"></el-table-column>
							<el-table-column prop="attribute" label="自定义属性" align="center"></el-table-column>
							<el-table-column prop="price" label="采购价" align="center"></el-table-column>
							<el-table-column prop="count" label="数量" align="center"></el-table-column>
							<el-table-column label="小计" align="center">
								<template slot-scope="scope">
									<span>{{ (Number(scope.row.price) * scope.row.count).toFixed(2) }}</span>
								</template>
							</el-table-column>
						</el-table>
					</template>
				</el-table-column>
				<el-table-column label="退库单号" prop="sn" align="center"></el-table-column>
				<el-table-column label="退库日期" prop="date" align="center"></el-table-column>
				<el-table-column label="退库仓库" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.warehouse.name }}</span>
					</template>
				</el-table-column>
				<el-table-column label="退回供货商" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.erp_supplier.name }}</span>
					</template>
				</el-table-column>
				<el-table-column label="应退金额" prop="amount" align="center"></el-table-column>
				<el-table-column label="状态" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.status == 0">草稿</span>
						<span v-if="scope.row.status == 1">已确认</span>
						<span v-if="scope.row.status == 2">已冲红</span>
						<span v-if="scope.row.status == 9">已作废</span>
					</template>
				</el-table-column>
				<el-table-column label="经办人" prop="handler" align="center"></el-table-column>
				<el-table-column label="改库存" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.modify_stock == 1">是</span>
						<span v-else>否</span>
					</template>
				</el-table-column>
				<el-table-column label="是否结清" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.settlemented_status == 0" style="color: red;">未结清</span>
						<span v-if="scope.row.settlemented_status == 1" style="color: green;">已结清</span>
					</template>
				</el-table-column>
				<el-table-column label="备注" prop="remark" align="center"></el-table-column>
				<el-table-column label="创建时间" prop="created_at" align="center"></el-table-column>
				<el-table-column label="操作" align="center" width="300">
					<template slot-scope="scope">
						<el-button type="warning" size="mini" @click.stop="dialogVisible = true, dialogItem = scope.row"
							v-if="scope.row.status == 0">确认</el-button>
						<el-button type="danger" size="mini" @click.stop="operationFn(2, scope.row)"
							v-if="scope.row.status == 0">作废</el-button>
						<el-button type="success" size="mini" @click.stop="changeOrder(scope.row)"
							v-if="scope.row.status == 0">改单</el-button>
						<el-button type="danger" size="mini" @click.stop="operationFn(3, scope.row)"
							v-if="scope.row.status == 1">冲红</el-button>
						<el-button type="primary" size="mini" @click.stop="settleFn(scope.row)"
							v-if="scope.row.settlemented_status == 0">结清</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination class="page" @size-change="pageNum = $event, loading = true, getList()"
				@current-change="page = $event, loading = true, getList()" :current-page="page" :page-sizes="pageSizes"
				layout="total, sizes, prev, pager, next, jumper" :page-size="pageNum" :total="total">
			</el-pagination>
		</div>
		<!-- 新增编辑 -->
		<el-dialog title="退库确认" :visible.sync="dialogVisible" :close-on-click-modal="false" v-el-drag-dialog
			width="30%">
			<el-form ref="form" :model="dialogForm" label-width="140px">
				<el-form-item label="同步可售库存：">
					<el-radio-group v-model="dialogForm.is_stock">
						<el-radio :label="0">不同步</el-radio>
						<el-radio :label="1">同步</el-radio>
					</el-radio-group>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click.stop="dialogVisible = false">取 消</el-button>
				<el-button type="warning" @click.stop="operationFn(1, dialogItem)">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				dateVal: [],
				searchForm: {
					begin: '',
					end: '',
				},
				// 仓库
				warehouseOptions: [],
				supplierList: [], //供应商
				// 状态
				statusOptions: [{
						id: 0,
						name: '草稿'
					},
					{
						id: 1,
						name: '已确认'
					},
					{
						id: 2,
						name: '已冲红'
					},
					{
						id: 9,
						name: '已作废'
					}
				],
				// 结清状态
				settleOptions: [{
						id: 0,
						name: '未结清'
					},
					{
						id: 1,
						name: '已结清'
					}
				],
				tableData: [],
				loading: true,
				page: 1,
				pageNum: 10,
				total: 0,
				pageSizes: [10, 50, 200, 1000],
				// 弹窗
				dialogItem: {},
				dialogVisible: false,
				dialogForm: {
					is_stock: 0,
					is_cost: 0
				}
			}
		},
		mounted() {
			// 仓库
			this.getWarehouseList()
			// 供应商
			this.getSupplierList()
			// 时间
			this.dateVal = [this.getCurrentDate() + ' ' + '00:00:00', this.getCurrentDate() + ' ' + '23:59:59']
			this.searchForm.begin = this.dateVal[0]
			this.searchForm.end = this.dateVal[1]
			// 列表
			this.getList()
		},
		methods: {
			getList() {
				this.$http.post(`/erp/v1/tkd/lst`, {
					page: this.page,
					...this.searchForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.tableData = data.rows
						this.total = data.total
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 获取当前年月日
			getCurrentDate() {
				const today = new Date();
				let year = today.getFullYear();
				let month = (today.getMonth() + 1).toString().padStart(2, '0');
				let day = today.getDate().toString().padStart(2, '0');
				return `${year}-${month}-${day}`;
			},
			// 仓库
			getWarehouseList() {
				this.$http.post(`erp/v1/warehouse/all_zong`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.warehouseOptions = data
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 供应商
			getSupplierList() {
				this.$http.post(`/erp/v1/supplier/all`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.supplierList = data
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 确认/作废
			operationFn(type, item) {
				this.$confirm(`确定操作该数据吗, 是否继续?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let info = {}
					let url = type == 1 ? '/erp/v1/tkd/confirm' : (type == 2 ? '/erp/v1/tkd/cancel' :
						'/erp/v1/tkd/unconfirm')
					if (type == 1) {
						info = this.dialogForm
					} else {
						info = {}
					}
					this.$http.post(`${url}`, {
						id: item.id,
						...info
					}).then((res) => {
						const {
							code,
							data,
							msg
						} = res.data
						if (code == 200) {
							this.getList()
							this.$message.success(msg);
							this.dialogVisible = false
						} else {
							this.$message.error(msg);
						}
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});
			},
			// 改单
			changeOrder(item) {
				this.$router.push({
					path: '/tkdAdd',
					query: {
						id: item.id
					}
				});
				// 存储路由
				sessionStorage.setItem('activeMenu', 'tkdAdd');
				setTimeout(() => {
					window.location.reload();
				}, 1000)
			},
			// 结清
			settleFn(item) {
				this.$confirm(`确定结清该数据吗, 是否继续?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post(`/erp/v1/tkd/settle`, {
						id: item.id
					}).then((res) => {
						const {
							code,
							data,
							msg
						} = res.data
						if (code == 200) {
							this.getList()
							this.$message.success(msg);
						} else {
							this.$message.error(msg);
						}
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});
			},
		},
	}
</script>

<style lang="scss" scoped>
	.export-template {
		#template-title {
			.title-t {
				text-align: center;
				margin-bottom: 20px;
				font-weight: bold;
				font-size: 22px;

			}

			.title-box {
				display: flex;
				flex-wrap: wrap;

				.box-item {
					width: 50%;
					display: flex;
					justify-content: center;
					margin-bottom: 20px;

					.item-i {
						width: 60%;

						span {
							padding-right: 20px;
						}
					}
				}
			}
		}
	}

	.page {
		margin-top: 20px;
		text-align: right;
	}
</style>